import { loading, getAuthInfo, setAuthInfo } from "./tool"
import { APIPATH } from './constant'
// const apiUrl = `${process.env.BASE_PROTOCOL}://${process.env.BASE_URL}/api`
const apiUrl = `${window.location.origin}/api`
// const apiUrl = `/api`
import { v4 as uuidv4 } from 'uuid';
export default (param) => {
  const authInfo = getAuthInfo()
  if (authInfo) {
    if (!authInfo.DeviceId || authInfo.DeviceId == 'Netscape' || authInfo.DeviceId === 'f3539ae01f5d5dadbd5b242a24a1ef21') {
      let uuid = uuidv4(
        `psb_${authInfo.Platform}_${new Date()}`)
      uuid = uuid.replace(/-/g, '')
      authInfo.DeviceId = uuid
      setAuthInfo(authInfo)
    }
  }
  let config = {
    url: param.customUrl ? param.customUrl : `${apiUrl}${param.url}`, //请求url, customUrl:自定义请求地址
    data: param.data || {}, //请求参数
    type: param.type || 'post', //请求方式，默认为post
    loading: param.loading || false, //是否需要loading动画，默认为false
    headers: param.headers || {},
    uploadFile: param.uploadFile || false
  }
  if (!config.url) {
    alert('url required');
    return
  }
  Object.assign(config.data, {
    DeviceId: authInfo.DeviceId,
    Platform: authInfo.Platform,
    ApiVersion: authInfo.ApiVersion,
    ClientAppCode: authInfo.ClientAppCode,
    Latlng: authInfo.Latlng,
    // AppId: 100000
  })
  if (config.loading) loading(true)
  return new Promise((resolve, reject) => {
    if (config.uploadFile) {
      console.log('config', config)
      let formData = new FormData();
      formData.append("Token", authInfo.Token);
      for (const key in config.data) {
        formData.append(key, config.data[key])
      }
      console.log('formData', formData)
      $.ajax({
        url: config.url,
        data: formData,
        type: config.type,
        processData: false,
        contentType: false,
        success: function (res) {
          resolve(res)
        },
        error: function (err) {
          reject(err)
        },
        complete: function (params) {
          if (config.loading) {
            loading(false)
          }
        }
      })
    } else {
      $.ajax({
        url: config.url,
        data: JSON.stringify(config.data),
        type: config.type,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Token': authInfo.Token,
          ...config.headers
        },
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        success: function (res) {
          resolve(res)
        },
        error: function (err) {
          reject(err)
        },
        complete: function (params) {
          if (config.loading) {
            loading(false)
          }
        }
      })
    }

  })
}